import {
  SET_UNAUTHENTICATED,SET_AUTHENTICATED,
  SET_ERRORS,
  CONNECTING,
  SET_LOGOUTST,
  SET_SESSION1,
  RM_USR_EXTENSION
} from "../types";
import { useHistory } from 'react-router';
import axios from "axios";




export const detectChange = (userData, history) => (dispatch) => {
  axios
    .post("/signup", userData)
    .then((res) => {
      setAuthorizationHeader(res.response.token);
      history.push("/");
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
    });
};


export const gropedit = (userData) => (dispatch) => {
  let config = {
    headers: {
      'Content-Type': 'text/plain; charset=UTF-8',
    }
  }
  axios
    .post("/gropedit",userData,config)
    .then((res) => {
      console.log("Grop edit response status: ok \n"+res.data);
      
    })
    .catch((err) => {
      console.log(err)
      dispatch({
        type: SET_ERRORS,
        payload: err.data,
      });
    });

    
}
export const volumeControll = (userData) => (dispatch) => {
  let config = {
    headers: {
      'Content-Type': 'text/plain; charset=UTF-8',
    }
  }
  axios
    .post("/volume",userData,config)
    .then((res) => {
      console.log("volume response status: ok \n"+res.data);
      
    })
    .catch((err) => {
      console.log(err)
      dispatch({
        type: SET_ERRORS,
        payload: err.data,
      });
    });

    
}
export const userextension = () => (dispatch) => {
  let config = {
    headers: {
      'Content-Type': 'text/plain; charset=UTF-8',
    }
  }
  axios
    .post("/user-extension",config)
    .then((res) => {
      console.log(res.data.extension);
      localStorage.setItem("extension_num",res.data.extension);
      
    })
    .catch((err) => {
      console.log(err)
      dispatch({
        type: SET_ERRORS,
        payload: err.data,
      });
    });

    
}

export const userexten = (userData) => (dispatch) => {
  let config = {
    headers: {
      'Content-Type': 'text/plain; charset=UTF-8',
    }
  }
  axios
    .post("/user-extension-status", userData,config)
    .then((res) => {
      console.log(res.data);
      localStorage.removeItem('extension_num');
    })
    .catch((err) => {
      console.log(err)
      dispatch({
        type: SET_ERRORS,
        payload: err.data,
      });
    });
};

export const sessionUsertimer = (userData) => (dispatch) => {
  let config = {
    headers: {
      'Content-Type': 'text/plain; charset=UTF-8',
    }
  }
  axios
    .post("/session-user-timer", userData,config)
    .then((res) => {
      console.log(res.data);
      // setAuthorizationHeader(res.data);
      let tab_session = sessionStorage.getItem("tab_session");
      let session_id = localStorage.getItem("session_id");
      let session_separator_usrname = localStorage.getItem("session_separator_usrname");
      if(session_separator_usrname == res.data.username && session_id == res.data.sesion_id && res.data.status == true && tab_session == res.data.tab_session){
     console.log("ok");

      }
      else{
        window.uaa.stop();
        
        dispatch({
          type: SET_UNAUTHENTICATED,
          payload: res.data
        });
        if(session_separator_usrname != null && session_id != null && tab_session != null) {
          dispatch({
            type: SET_LOGOUTST,
            payload: res.data
          });
        }
        sessionStorage.removeItem('tab_session');
        window.screen_lockstate.disable(); // let the screen turn off.
      }

    })
    .catch((err) => {
      console.log(err)
      dispatch({
        type: SET_ERRORS,
        payload: err.data,
      });
    });
};

export const sessionUser = (userData) => (dispatch) => {
  let config = {
    headers: {
      'Content-Type': 'text/plain; charset=UTF-8',
    }
  }
  axios
    .post("/session-user", userData,config)
    .then((res) => {
      console.log(res.data);
      // setAuthorizationHeader(res.data);
      let session_id = localStorage.getItem("session_id");
      let session_separator_usrname = localStorage.getItem("session_separator_usrname");
      if(session_separator_usrname == res.data.username && session_id == res.data.sesion_id && res.data.status == true){
        dispatch({
          type: SET_AUTHENTICATED,
          username:res.data.username,
          sessiion_id: res.data.sesion_id,

        });

      }
      else{
        localStorage.removeItem('session_id');
        localStorage.removeItem('session_separator_usrname');
        console.log("res.data res.data");
        dispatch({
          type: SET_UNAUTHENTICATED,
          payload: res.data,
          usr_ext_state : 1
        });
      }

    })
    .catch((err) => {
      console.log(err)
      dispatch({
        type: SET_ERRORS,
        payload: err.data,
      });
    });
};

export const loginUser = (userData) => (dispatch) => {
  let config = {
    headers: {
      'Content-Type': 'text/plain; charset=UTF-8',
    }
  }
  axios
    .post("/login", userData,config)
    .then((res) => {
      console.log(res.data.command);
      if (res.data.command == "OTP") {
        window.encry = true;
      }
      else {
        window.encry = false;
      }
      // setAuthorizationHeader(res.data);
      // dispatch({
      //   type: CONNECTING,
      //   payload: res.data
      // });

    })
    .catch((err) => {
      console.log(err)
      dispatch({
        type: SET_ERRORS,
        payload: err.data,
      });
    });
};

// Set User; Set Authorization Header;
export const setAuthorizationHeader = (token) => {
  const FBToken = `Bearer ${token}`;
  localStorage.setItem("FbIdToken", FBToken);
  axios.defaults.headers.common["Authorization"] = FBToken;
};



export const logoutUser = (history, userData) => (dispatch) => {
  let config = {
    headers: {
      'Content-Type': 'text/plain; charset=UTF-8',
    }
  }
  axios
    .post("/logout", userData,config)
    .then((res) => {
      //console.log(res.data);
      console.log("Deletion Request Sent")
      setAuthorizationHeader(res.data);
      dispatch({
        type: SET_UNAUTHENTICATED,
        payload: res.data,
      });
      window.uaa.stop();
      sessionStorage.removeItem("tab_session");
      localStorage.removeItem('session_id');
      localStorage.removeItem('session_separator_usrname');
      window.screen_lockstate.disable(); // let the screen turn off.
      localStorage.removeItem('screen_State');

    })
    .catch((err) => {
      console.log(err)
      dispatch({
        type: SET_ERRORS,
        payload: err.data,
      });
    });
};

