import React, { useState, useEffect } from "react";
import "./SpeedDial.css";
import WavePlayer from "../WavePlayer/WavePlayer";
import { trimAudio } from "./last_twenty.js";
import { Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { writereload, latchedstate } from "../../redux/actions/dialActions";
import { gropedit, volumeControll } from "../../redux/actions/userActions";
import { dialCall } from "../../redux/actions/dialActions";
import vbar from "../../assets/images/moveback.gif";
import statticpic from "../../assets/images/stop.png";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const SpeedDials = (props) => {
  const [DialButtonList, setDialButtonList] = useState(
    Array.from(Array(22).keys()).map((e) => ["Name", "Number"])
  );
  const wavePlayerRef = React.createRef();
  const [enablePlayer, setEnablePlayer] = useState(false);

  const dispatch = useDispatch();
  const callData = useSelector((state) => state.dial);
  const uiData = useSelector((state) => state.ui);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const hidePlayerModal = () => wavePlayerRef.current.stopOnModalClose();
  const handleShow = () => setShow(true);
  const [warn, setWarn] = useState(false);
  const handleClosewarn = () => setWarn(false);
  const handleShowwarn = () => setWarn(true);
  // this grpbtn use to manage the group buton
  const [grpbtn, setGrpbtn] = useState({
    8: "1",
    9: "2",
    10: "All",
  });
  const [grup, setGrup] = useState({
    1: 1,
    2: 1,
    3: 1,
    4: 1,
    5: 1,
    6: 1,
    7: 1,
  });
  const [volum, setVolum] = useState({
    1: 10,
    2: 20,
    3: 30,
    4: 40,
    5: 50,
    6: 60,
    7: 70,
  });
  //   this   use to enable and disable the button acording to call condistions
  const [callcondition, SetCallcondition] = useState({
    1: "false",
    2: "false",
    3: "false",
    4: "false",
    5: "false",
    6: "false",
    7: "false",
  });
  const [palyfunc, SetPalyfunc] = useState({
    1: "pause",
    2: "pause",
    3: "pause",
    4: "pause",
    5: "pause",
    6: "pause",
    7: "pause",
  });
  //   this statee is use to mange the group button state
  const [statee, setStatee] = useState({
    8: "Inactive",
    9: "Inactive",
    10: "Inactive",
    11: "Inactive",
  });
  //   this statetimer is use to mange the group button state timer
  const [statetimer, setStatetimer] = useState({
    8: "Inactive",
    9: "Inactive",
    10: "Inactive",
    11: "Inactive",
  });
  //   this mic is use to manage the the mic mute funcationlty
  const [mic, setMic] = useState({
    1: "Unmute",
    2: "Unmute",
    3: "Unmute",
    4: "Unmute",
    5: "Unmute",
    6: "Unmute",
    7: "Unmute",
  });
  //   this mic is use to manage radio funcanality the the mic mute funcationlty
  const [micagain, setMicagain] = useState({
    1: "Unmute",
    2: "Unmute",
    3: "Unmute",
    4: "Unmute",
    5: "Unmute",
    6: "Unmute",
    7: "Unmute",
  });
  //   this mic is use to manage the the color for grop edit funcationlty
  const [micg, setMicg] = useState({
    1: "Unmute",
    2: "Unmute",
    3: "Unmute",
    4: "Unmute",
    5: "Unmute",
    6: "Unmute",
    7: "Unmute",
  });
  // this vadd is use to mange the vadd funcationalty
  const [vadd, setVadd] = useState({
    1: "Voice_Not_Active",
    2: "Voice_Not_Active",
    3: "Voice_Not_Active",
    4: "Voice_Not_Active",
    5: "Voice_Not_Active",
    6: "Voice_Not_Active",
    7: "Voice_Not_Active",
  });
  // this vadd is use to mange the vadd fluctuation funcationalty
  const [vaddfluc, setVaddfluc] = useState({
    1: "vad_Not_Fluctuat",
    2: "vad_Not_Fluctuat",
    3: "vad_Not_Fluctuat",
    4: "vad_Not_Fluctuat",
    5: "vad_Not_Fluctuat",
    6: "vad_Not_Fluctuat",
    7: "vad_Not_Fluctuat",
  });
  // use for genrate the id for audio recorder
  const [recodrid, setRecodrid] = useState({
    1: "recodrid1",
    2: "recodrid2",
    3: "recodrid3",
    4: "recodrid4",
    5: "recodrid5",
    6: "recodrid6",
    7: "recodrid7",
  });
  const [recurl, setRecurl] = useState({
    1: "",
    2: "",
    3: "",
    4: "",
    5: "",
    6: "",
    7: "",
  });

  var vad = require("./vadd.js");
  
  //these below all 7 useeffects are use to manage the vad funcatioanlty
  // Audio channel 1 for vad funcationlaty
  useEffect(() => {
    if (window.steamchannel_1) {
      // use for recoding the playback audio

      // Create an AudioContext
      const audioContext = new AudioContext();
      

      window.mediaRecorder1 = new MediaRecorder(window.steamchannel_1);


      window.mediaRecorder1.start();
      console.log("recodring start 1");
      
      window.mediaRecorder1.addEventListener("start", (event) => {
        console.log("audio start 1 event accure");
        window.audioChunks1 = [];
        if (palyfunc[1] == "play") {
          // window.audio1.controls = false;
          SetPalyfunc((previousState) => {
            return { ...previousState, 1: "pause" };
          });
        }
      });
      
      window.audioChunks1 = [];
      window.mediaRecorder1.addEventListener("dataavailable", (event) => {
        window.audioChunks1.push(event.data);
        console.log(event.data);
      });

      window.mediaRecorder1.addEventListener("stop", () => {
        // const chunks = window.audioChunks1;
        // debugger;
        // const last2Chunks = chunks.slice(-2);
        const audioBlob = new Blob(window.audioChunks1);
        console.log("recodring paly 1");
        console.log(window.audioChunks1);
        window.audio1 = document.getElementById(recodrid[1]);
        trimAudio(audioBlob, window.audio1);
        // setEnablePlayer(true);
        (async () => {
          console.log("waiting for variable");
          while (!window.hasOwnProperty("ssrul") || window.ssrul == "")
            await new Promise((resolve) => setTimeout(resolve, 100));
          console.log("variable is defined " + window.ssrul);
          setRecurl((previousState) => {
            return {
              ...previousState,
              1: window.ssrul,
            };
          });
            SetPalyfunc((previousState) => {
              return { ...previousState, 1: "play" };
            });
        })();
        // setTimeout(() => {
        //   console.log("main page url " + window.ssrul);
        // }, 200);

        // window.audio1.src = URL.createObjectURL(audioBlob);
        // window.audio1.controls = true;
      });
    }
    if (window.steamchannel_1) {
      startUserMedia1(window.steamchannel_1);
      function startUserMedia1(stream) {
        window.AudioContext = window.AudioContext || window.webkitAudioContext;
        var audioContext = new AudioContext();
        var state = 0;
        var options = {
          onVoiceStart: function () {
            setVadd((previousState) => {
              return { ...previousState, 1: "Voice_Active" };
            });
            setVaddfluc((previousState) => {
              return { ...previousState, 1: "vad_Not_Fluctuat" };
            });
            state = 1;
            console.log("voice start on channel 1");
          },
          onVoiceStop: function () {
            setVaddfluc((previousState) => {
              return { ...previousState, 1: "vad_Fluctuat" };
            });
            state = 0;

            console.log("voice stop on channel 1");
            setTimeout(() => {
              if (state == 0) {
                setVadd((previousState) => {
                  return { ...previousState, 1: "Voice_Not_Active" };
                });
                setVaddfluc((previousState) => {
                  return { ...previousState, 1: "vad_Not_Fluctuat" };
                });
              }
            }, 2000);
          },
          onUpdate: function (val) {
            //console.log('curr val:', val);
          },
        };
        vad(audioContext, stream, options);
      }
    }
  }, [window.steamchannel_1]);

  // Audio channel 2 for vad funcationlaty
  useEffect(() => {
    if (window.steamchannel_2) {
      // use for recoding the playback audio
      window.mediaRecorder2 = new MediaRecorder(window.steamchannel_2);
      window.mediaRecorder2.start();
      console.log("recodring start 2");
      
      window.mediaRecorder2.addEventListener("start", (event) => {
        console.log("audio start 2 event accure");
        window.audioChunks2 = [];
        if (palyfunc[2] == "play") {
          // window.audio2.controls = false;
          SetPalyfunc((previousState) => {
            return { ...previousState, 2: "pause" };
          });
        }
      });

      window.audioChunks2 = [];
      window.mediaRecorder2.addEventListener("dataavailable", (event) => {
        window.audioChunks2.push(event.data);
        console.log(event.data);
      });

      window.mediaRecorder2.addEventListener("stop", () => {
        const audioBlob = new Blob(window.audioChunks2);
        console.log("recodring paly 2");
        window.audio2 = document.getElementById(recodrid[2]);
        trimAudio(audioBlob, window.audio2);
        (async () => {
          console.log("waiting for variable");
          while (!window.hasOwnProperty("ssrul") || window.ssrul == "")
            await new Promise((resolve) => setTimeout(resolve, 100));
          console.log("variable is defined " + window.ssrul);
          setRecurl((previousState) => {
            return {
              ...previousState,
              2: window.ssrul,
            };
          });
            SetPalyfunc((previousState) => {
              return { ...previousState, 2: "play" };
            });
        })();

        // window.audio2.src = URL.createObjectURL(audioBlob);
        // window.audio2.controls = true;
      });
    }
    if (window.steamchannel_2) {
      startUserMedia1(window.steamchannel_2);
      function startUserMedia1(stream) {
        window.AudioContext = window.AudioContext || window.webkitAudioContext;
        var audioContext = new AudioContext();
        var state = 0;
        var options = {
          onVoiceStart: function () {
            setVadd((previousState) => {
              return { ...previousState, 2: "Voice_Active" };
            });
            setVaddfluc((previousState) => {
              return { ...previousState, 2: "vad_Not_Fluctuat" };
            });
            state = 1;
            console.log("voice start on channel 2");
          },
          onVoiceStop: function () {
            setVaddfluc((previousState) => {
              return { ...previousState, 2: "vad_Fluctuat" };
            });
            state = 0;

            console.log("voice stop on channel 2");
            setTimeout(() => {
              if (state == 0) {
                setVadd((previousState) => {
                  return { ...previousState, 2: "Voice_Not_Active" };
                });
                setVaddfluc((previousState) => {
                  return { ...previousState, 2: "vad_Not_Fluctuat" };
                });
              }
            }, 2000);
          },
          onUpdate: function (val) {
            //console.log('curr val:', val);
          },
        };
        vad(audioContext, stream, options);
      }
    }
  }, [window.steamchannel_2]);

  // Audio channel 3 for vad funcationlaty
  useEffect(() => {
    if (window.steamchannel_3) {
      // use for recoding the playback audio
      window.mediaRecorder3 = new MediaRecorder(window.steamchannel_3);
      window.mediaRecorder3.start();
      console.log("recodring start 3");

      window.mediaRecorder3.addEventListener("start", (event) => {
        console.log("audio start 3 event accure");
        window.audioChunks3 = [];
        if (palyfunc[3] == "play") {
          // window.audio3.controls = false;
          SetPalyfunc((previousState) => {
            return { ...previousState, 3: "pause" };
          });
        }
      });
      window.audioChunks3 = [];
      window.mediaRecorder3.addEventListener("dataavailable", (event) => {
        window.audioChunks3.push(event.data);
        console.log(event.data);
      });

      window.mediaRecorder3.addEventListener("stop", () => {
        const audioBlob = new Blob(window.audioChunks3);
        console.log("recodring paly 3");
        window.audio3 = document.getElementById(recodrid[3]);
        trimAudio(audioBlob, window.audio3);
        (async () => {
          console.log("waiting for variable");
          while (!window.hasOwnProperty("ssrul") || window.ssrul == "")
            await new Promise((resolve) => setTimeout(resolve, 100));
          console.log("variable is defined " + window.ssrul);
          setRecurl((previousState) => {
            return {
              ...previousState,
              3: window.ssrul,
            };
          });
            SetPalyfunc((previousState) => {
              return { ...previousState, 3: "play" };
            });
        })();
        // window.audio3.src = URL.createObjectURL(audioBlob);
        // window.audio3.controls = true;
      });
    }
    if (window.steamchannel_3) {
      startUserMedia1(window.steamchannel_3);
      function startUserMedia1(stream) {
        window.AudioContext = window.AudioContext || window.webkitAudioContext;
        var audioContext = new AudioContext();
        var state = 0;
        var options = {
          onVoiceStart: function () {
            setVadd((previousState) => {
              return { ...previousState, 3: "Voice_Active" };
            });
            setVaddfluc((previousState) => {
              return { ...previousState, 3: "vad_Not_Fluctuat" };
            });
            state = 1;
            console.log("voice start on channel 3");
          },
          onVoiceStop: function () {
            setVaddfluc((previousState) => {
              return { ...previousState, 3: "vad_Fluctuat" };
            });
            state = 0;

            console.log("voice stop on channel 3");
            setTimeout(() => {
              if (state == 0) {
                setVadd((previousState) => {
                  return { ...previousState, 3: "Voice_Not_Active" };
                });
                setVaddfluc((previousState) => {
                  return { ...previousState, 3: "vad_Not_Fluctuat" };
                });
              }
            }, 2000);
          },
          onUpdate: function (val) {
            //console.log('curr val:', val);
          },
        };
        vad(audioContext, stream, options);
      }
    }
  }, [window.steamchannel_3]);

  // Audio channel 4 for vad funcationlaty
  useEffect(() => {
    if (window.steamchannel_4) {
      // use for recoding the playback audio
      window.mediaRecorder4 = new MediaRecorder(window.steamchannel_4);
      window.mediaRecorder4.start();
      console.log("recodring start 4");

      window.mediaRecorder4.addEventListener("start", (event) => {
        console.log("audio start 4 event accure");
        window.audioChunks4 = [];
        if (palyfunc[4] == "play") {
          // window.audio4.controls = false;
          SetPalyfunc((previousState) => {
            return { ...previousState, 4: "pause" };
          });
        }
      });

      window.audioChunks4 = [];
      window.mediaRecorder4.addEventListener("dataavailable", (event) => {
        window.audioChunks4.push(event.data);
        console.log(event.data);
      });

      window.mediaRecorder4.addEventListener("stop", () => {
        const audioBlob = new Blob(window.audioChunks4);
        console.log("recodring paly 4");
        window.audio4 = document.getElementById(recodrid[4]);
        trimAudio(audioBlob, window.audio4);
        (async () => {
          console.log("waiting for variable");
          while (!window.hasOwnProperty("ssrul") || window.ssrul == "")
            await new Promise((resolve) => setTimeout(resolve, 100));
          console.log("variable is defined " + window.ssrul);
          setRecurl((previousState) => {
            return {
              ...previousState,
              4: window.ssrul,
            };
          });
            SetPalyfunc((previousState) => {
              return { ...previousState, 4: "play" };
            });
        })();
        // window.audio4.src = URL.createObjectURL(audioBlob);
        // window.audio4.controls = true;
      });
    }
    if (window.steamchannel_4) {
      startUserMedia1(window.steamchannel_4);
      function startUserMedia1(stream) {
        window.AudioContext = window.AudioContext || window.webkitAudioContext;
        var audioContext = new AudioContext();
        var state = 0;
        var options = {
          onVoiceStart: function () {
            setVadd((previousState) => {
              return { ...previousState, 4: "Voice_Active" };
            });
            setVaddfluc((previousState) => {
              return { ...previousState, 4: "vad_Not_Fluctuat" };
            });
            state = 1;
            console.log("voice start on channel 4");
          },
          onVoiceStop: function () {
            setVaddfluc((previousState) => {
              return { ...previousState, 4: "vad_Fluctuat" };
            });
            state = 0;

            console.log("voice stop on channel 4");
            setTimeout(() => {
              if (state == 0) {
                setVadd((previousState) => {
                  return { ...previousState, 4: "Voice_Not_Active" };
                });
                setVaddfluc((previousState) => {
                  return { ...previousState, 4: "vad_Not_Fluctuat" };
                });
              }
            }, 2000);
          },
          onUpdate: function (val) {
            //console.log('curr val:', val);
          },
        };
        vad(audioContext, stream, options);
      }
    }
  }, [window.steamchannel_4]);

  // Audio channel 5 for vad funcationlaty
  useEffect(() => {
    if (window.steamchannel_5) {
      // use for recoding the playback audio
      window.mediaRecorder5 = new MediaRecorder(window.steamchannel_5);
      window.mediaRecorder5.start();
      console.log("recodring start 5");

      window.mediaRecorder5.addEventListener("start", (event) => {
        console.log("audio start 5 event accure");
        window.audioChunks5 = [];
        if (palyfunc[5] == "play") {
          // window.audio5.controls = false;
          SetPalyfunc((previousState) => {
            return { ...previousState, 5: "pause" };
          });
        }
      });

      window.audioChunks5 = [];
      window.mediaRecorder5.addEventListener("dataavailable", (event) => {
        window.audioChunks5.push(event.data);
        console.log(event.data);
      });

      window.mediaRecorder5.addEventListener("stop", () => {
        const audioBlob = new Blob(window.audioChunks5);
        console.log("recodring paly 5");
        window.audio5 = document.getElementById(recodrid[5]);
          trimAudio(audioBlob, window.audio5);
        (async () => {
          console.log("waiting for variable");
          while (!window.hasOwnProperty("ssrul") || window.ssrul == "")
            await new Promise((resolve) => setTimeout(resolve, 100));
          console.log("variable is defined " + window.ssrul);
          setRecurl((previousState) => {
            return {
              ...previousState,
              5: window.ssrul,
            };
          });
            SetPalyfunc((previousState) => {
              return { ...previousState, 5: "play" };
            });
        })();
        // window.audio5.src = URL.createObjectURL(audioBlob);
        // window.audio5.controls = true;
      });
    }
    if (window.steamchannel_5) {
      startUserMedia1(window.steamchannel_5);
      function startUserMedia1(stream) {
        window.AudioContext = window.AudioContext || window.webkitAudioContext;
        var audioContext = new AudioContext();
        var state = 0;
        var options = {
          onVoiceStart: function () {
            setVadd((previousState) => {
              return { ...previousState, 5: "Voice_Active" };
            });
            setVaddfluc((previousState) => {
              return { ...previousState, 5: "vad_Not_Fluctuat" };
            });
            state = 1;
            console.log("voice start on channel 5");
          },
          onVoiceStop: function () {
            setVaddfluc((previousState) => {
              return { ...previousState, 5: "vad_Fluctuat" };
            });
            state = 0;

            console.log("voice stop on channel 5");
            setTimeout(() => {
              if (state == 0) {
                setVadd((previousState) => {
                  return { ...previousState, 5: "Voice_Not_Active" };
                });
                setVaddfluc((previousState) => {
                  return { ...previousState, 5: "vad_Not_Fluctuat" };
                });
              }
            }, 2000);
          },
          onUpdate: function (val) {
            //console.log('curr val:', val);
          },
        };
        vad(audioContext, stream, options);
      }
    }
  }, [window.steamchannel_5]);

  // Audio channel 6 for vad funcationlaty
  useEffect(() => {
    if (window.steamchannel_6) {
      // use for recoding the playback audio
      window.mediaRecorder6 = new MediaRecorder(window.steamchannel_6);
      window.mediaRecorder6.start();
      console.log("recodring start 6");
      
      window.mediaRecorder6.addEventListener("start", (event) => {
        console.log("audio start 6 event accure");
        window.audioChunks6 = [];
        if (palyfunc[6] == "play") {
          // window.audio6.controls = false;
          SetPalyfunc((previousState) => {
            return { ...previousState, 6: "pause" };
          });
        }
      });

      window.audioChunks6 = [];
      window.mediaRecorder6.addEventListener("dataavailable", (event) => {
        window.audioChunks6.push(event.data);
        console.log(event.data);
      });

      window.mediaRecorder6.addEventListener("stop", () => {
        const audioBlob = new Blob(window.audioChunks6);
        console.log("recodring paly 6");
        window.audio6 = document.getElementById(recodrid[6]);
        trimAudio(audioBlob, window.audio6);
        (async () => {
          console.log("waiting for variable");
          while (!window.hasOwnProperty("ssrul") || window.ssrul == "")
            await new Promise((resolve) => setTimeout(resolve, 100));
          console.log("variable is defined " + window.ssrul);
          setRecurl((previousState) => {
            return {
              ...previousState,
              6: window.ssrul,
            };
          });
            SetPalyfunc((previousState) => {
              return { ...previousState, 6: "play" };
            });
        })();
        // window.audio6.src = URL.createObjectURL(audioBlob);
        // window.audio6.controls = true;
      });
    }
    if (window.steamchannel_6) {
      startUserMedia1(window.steamchannel_6);
      function startUserMedia1(stream) {
        window.AudioContext = window.AudioContext || window.webkitAudioContext;
        var audioContext = new AudioContext();
        var state = 0;
        var options = {
          onVoiceStart: function () {
            setVadd((previousState) => {
              return { ...previousState, 6: "Voice_Active" };
            });
            setVaddfluc((previousState) => {
              return { ...previousState, 6: "vad_Not_Fluctuat" };
            });
            state = 1;
            console.log("voice start on channel 6");
          },
          onVoiceStop: function () {
            setVaddfluc((previousState) => {
              return { ...previousState, 6: "vad_Fluctuat" };
            });
            state = 0;

            console.log("voice stop on channel 6");
            setTimeout(() => {
              if (state == 0) {
                setVadd((previousState) => {
                  return { ...previousState, 6: "Voice_Not_Active" };
                });
                setVaddfluc((previousState) => {
                  return { ...previousState, 6: "vad_Not_Fluctuat" };
                });
              }
            }, 2000);
          },
          onUpdate: function (val) {
            //console.log('curr val:', val);
          },
        };
        vad(audioContext, stream, options);
      }
    }
  }, [window.steamchannel_6]);

  // Audio channel 7 for vad funcationlaty
  useEffect(() => {
    if (window.steamchannel_7) {
      // use for recoding the playback audio
      window.mediaRecorder7 = new MediaRecorder(window.steamchannel_7);
      window.mediaRecorder7.start();
      console.log("recodring start 7");
     
      window.mediaRecorder7.addEventListener("start", (event) => {
        console.log("audio start 7 event accure");
        window.audioChunks7 = [];
        if (palyfunc[7] == "play") {
          // window.audio7.controls = false;
          SetPalyfunc((previousState) => {
            return { ...previousState, 7: "pause" };
          });
        }
      });

      window.audioChunks7 = [];
      window.mediaRecorder7.addEventListener("dataavailable", (event) => {
        window.audioChunks7.push(event.data);
        console.log(event.data);
      });

      window.mediaRecorder7.addEventListener("stop", () => {
        const audioBlob = new Blob(window.audioChunks7);
        console.log("recodring paly 7");
        window.audio7 = document.getElementById(recodrid[7]);
        trimAudio(audioBlob, window.audio7);
        (async () => {
          console.log("waiting for variable");
          while (!window.hasOwnProperty("ssrul") || window.ssrul == "")
            await new Promise((resolve) => setTimeout(resolve, 100));
          console.log("variable is defined " + window.ssrul);
          setRecurl((previousState) => {
            return {
              ...previousState,
              7: window.ssrul,
            };
          });
            SetPalyfunc((previousState) => {
              return { ...previousState, 7: "play" };
            });
        })();
        // window.audio7.src = URL.createObjectURL(audioBlob);
        // window.audio7.controls = true;
      });
    }
    if (window.steamchannel_7) {
      startUserMedia1(window.steamchannel_7);
      function startUserMedia1(stream) {
        window.AudioContext = window.AudioContext || window.webkitAudioContext;
        var audioContext = new AudioContext();
        var state = 0;
        var options = {
          onVoiceStart: function () {
            setVadd((previousState) => {
              return { ...previousState, 7: "Voice_Active" };
            });
            setVaddfluc((previousState) => {
              return { ...previousState, 7: "vad_Not_Fluctuat" };
            });
            state = 1;
            console.log("voice start on channel 7");
          },
          onVoiceStop: function () {
            setVaddfluc((previousState) => {
              return { ...previousState, 7: "vad_Fluctuat" };
            });
            state = 0;

            console.log("voice stop on channel 7");
            setTimeout(() => {
              if (state == 0) {
                setVadd((previousState) => {
                  return { ...previousState, 7: "Voice_Not_Active" };
                });
                setVaddfluc((previousState) => {
                  return { ...previousState, 7: "vad_Not_Fluctuat" };
                });
              }
            }, 2000);
          },
          onUpdate: function (val) {
            //console.log('curr val:', val);
          },
        };
        vad(audioContext, stream, options);
      }
    }
  }, [window.steamchannel_7]);

  // the following is used for handling the button status . like enable or disbale.
  // and hold the call status.
  var kl = 0;
  useEffect(() => {
    setInterval(() => {
      // kl++;
      // console.log("setsionn id here and temm message "+kl);

      if (window.steamchannel_1) {
        if (window.steamchannel_1.active == true) {
          SetCallcondition((previousState) => {
            window.state1 = false;
            return { ...previousState, 1: "true" };
          });
        }
        if (window.steamchannel_1.active == false) {
          SetCallcondition((previousState) => {
            window.state1 = true;
            return { ...previousState, 1: "false" };
          });
        }
      }
      if (window.steamchannel_2) {
        if (window.steamchannel_2.active == true) {
          SetCallcondition((previousState) => {
            window.state2 = false;
            return { ...previousState, 2: "true" };
          });
        }
        if (window.steamchannel_2.active == false) {
          SetCallcondition((previousState) => {
            window.state2 = true;
            return { ...previousState, 2: "false" };
          });
        }
      }
      if (window.steamchannel_3) {
        if (window.steamchannel_3.active == true) {
          SetCallcondition((previousState) => {
            window.state3 = false;
            return { ...previousState, 3: "true" };
          });
        }
        if (window.steamchannel_3.active == false) {
          SetCallcondition((previousState) => {
            window.state3 = true;
            return { ...previousState, 3: "false" };
          });
        }
      }
      if (window.steamchannel_4) {
        if (window.steamchannel_4.active == true) {
          SetCallcondition((previousState) => {
            window.state4 = false;
            return { ...previousState, 4: "true" };
          });
        }
        if (window.steamchannel_4.active == false) {
          SetCallcondition((previousState) => {
            window.state4 = true;
            return { ...previousState, 4: "false" };
          });
        }
      }
      if (window.steamchannel_5) {
        if (window.steamchannel_5.active == true) {
          SetCallcondition((previousState) => {
            window.state5 = false;
            return { ...previousState, 5: "true" };
          });
        }
        if (window.steamchannel_5.active == false) {
          SetCallcondition((previousState) => {
            window.state5 = true;
            return { ...previousState, 5: "false" };
          });
        }
      }
      if (window.steamchannel_6) {
        if (window.steamchannel_6.active == true) {
          SetCallcondition((previousState) => {
            window.state6 = false;
            return { ...previousState, 6: "true" };
          });
        }
        if (window.steamchannel_6.active == false) {
          SetCallcondition((previousState) => {
            window.state6 = true;
            return { ...previousState, 6: "false" };
          });
        }
      }
      if (window.steamchannel_7) {
        if (window.steamchannel_7.active == true) {
          SetCallcondition((previousState) => {
            window.state7 = false;
            return { ...previousState, 7: "true" };
          });
        }
        if (window.steamchannel_7.active == false) {
          SetCallcondition((previousState) => {
            window.state7 = true;
            return { ...previousState, 7: "false" };
          });
        }
      }
    }, 6000);
  }, [
    window.steamchannel_1,
    window.steamchannel_2,
    window.steamchannel_3,
    window.steamchannel_4,
    window.steamchannel_5,
    window.steamchannel_6,
    window.steamchannel_7,
  ]);

  const dummy = () => {
    //
  };
  const volume_up = (btnn) => {
    // use for volume up , hit volume up api
    console.log("use for volume up", btnn);
    switch (btnn) {
      case 1:
        var volem = parseInt(volum[1]) + 5;
        console.log("use for volume up", volem);
        valum(btnn, "CH1", volem);
        break;
      case 2:
        var volem = parseInt(volum[2]) + 5;
        console.log("use for volume up", volem);
        valum(btnn, "CH2", volem);
        break;
      case 3:
        var volem = parseInt(volum[3]) + 5;
        console.log("use for volume up", volem);
        valum(btnn, "CH3", volem);
        break;
      case 4:
        var volem = parseInt(volum[4]) + 5;
        console.log("use for volume up", volem);
        valum(btnn, "CH4", volem);
        break;
      case 5:
        var volem = parseInt(volum[5]) + 5;
        console.log("use for volume up", volem);
        valum(btnn, "CH5", volem);
        break;
      case 6:
        var volem = parseInt(volum[6]) + 5;
        console.log("use for volume up", volem);
        valum(btnn, "CH6", volem);
        break;
      case 7:
        var volem = parseInt(volum[7]) + 5;
        console.log("use for volume up", volem);
        valum(btnn, "CH7", volem);
        break;
    }
  };
  const volume_down = (btnn) => {
    // use for volume down , hit volume down api
    console.log("use for volume down", btnn);
    switch (btnn) {
      case 1:
        var volem = parseInt(volum[1]) - 5;
        console.log("use for volume down", volem);
        valum(btnn, "CH1", volem);
        break;
      case 2:
        var volem = parseInt(volum[2]) - 5;
        console.log("use for volume down", volem);
        valum(btnn, "CH2", volem);
        break;
      case 3:
        var volem = parseInt(volum[3]) - 5;
        console.log("use for volume down", volem);
        valum(btnn, "CH3", volem);
        break;
      case 4:
        var volem = parseInt(volum[4]) - 5;
        console.log("use for volume down", volem);
        valum(btnn, "CH4", volem);
        break;
      case 5:
        var volem = parseInt(volum[5]) - 5;
        console.log("use for volume down", volem);
        valum(btnn, "CH5", volem);
        break;
      case 6:
        var volem = parseInt(volum[6]) - 5;
        console.log("use for volume down", volem);
        valum(btnn, "CH6", volem);
        break;
      case 7:
        var volem = parseInt(volum[7]) - 5;
        console.log("use for volume down", volem);
        valum(btnn, "CH7", volem);
        break;
    }
  };
  // use to blur the background while click on paly button
  const blurvv = (state) => {
    console.log("state ttttttttttt" + state);
    /* state can be 1 or 0 */
    var containerElement = document.getElementById('main_container');
    if (state == 1) {
      containerElement.setAttribute('class', 'blur');
    } else {
      containerElement.setAttribute('class', null);
    }

  }
  const paly_player = (btnn) => {
    if (window.calldata_1 != undefined) {
      if (window.mediaRecorder1.state == "inactive") {
        window.audioChunks1 = [];
        window.mediaRecorder1.start();
        window.ssrul = "";
        // window.audio1.controls = false;
        SetPalyfunc((previousState) => {
          return { ...previousState, 1: "pause" };
        });
      }
    }
    if (window.calldata_2 != undefined) {
      if (window.mediaRecorder2.state == "inactive") {
        window.audioChunks2 = [];
        window.mediaRecorder2.start();
        window.ssrul = "";
        // window.audio2.controls = false;
        SetPalyfunc((previousState) => {
          return { ...previousState, 2: "pause" };
        });
      }
    }
    if (window.calldata_3 != undefined) {
      if (window.mediaRecorder3.state == "inactive") {
        window.audioChunks3 = [];
        window.mediaRecorder3.start();
        window.ssrul = "";
        // window.audio3.controls = false;
        SetPalyfunc((previousState) => {
          return { ...previousState, 3: "pause" };
        });
      }
    }
    if (window.calldata_4 != undefined) {
      if (window.mediaRecorder4.state == "inactive") {
        window.audioChunks4 = [];
        window.mediaRecorder4.start();
        window.ssrul = "";
        // window.audio4.controls = false;
        SetPalyfunc((previousState) => {
          return { ...previousState, 4: "pause" };
        });
      }
    }

    if (window.calldata_5 != undefined) {
      if (window.mediaRecorder5.state == "inactive") {
        window.audioChunks5 = [];
        window.mediaRecorder5.start();
        window.ssrul = "";
        // window.audio5.controls = false;
        SetPalyfunc((previousState) => {
          return { ...previousState, 5: "pause" };
        });
      }
    }
    if (window.calldata_6 != undefined) {
      if (window.mediaRecorder6.state == "inactive") {
        window.audioChunks6 = [];
        window.mediaRecorder6.start();
        window.ssrul = "";
        // window.audio6.controls = false;
        SetPalyfunc((previousState) => {
          return { ...previousState, 6: "pause" };
        });
      }
    }
    if (window.calldata_7 != undefined) {
      if (window.mediaRecorder7.state == "inactive") {
        window.audioChunks7 = [];
        window.mediaRecorder7.start();
        window.ssrul = "";
        // window.audio7.controls = false;
        SetPalyfunc((previousState) => {
          return { ...previousState, 7: "pause" };
        });
      }
    }

    console.log(btnn);
    if (btnn == 1 && palyfunc[1] == "pause") {
      // stop the palyback recoder
      if (window.mediaRecorder1) {
        console.log("recodring stop on channel 1");
        window.mediaRecorder1.stop();
        // change the status of recording
        // SetPalyfunc((previousState) => {
        //   return { ...previousState, 1: "play" };
        // });
        console.log("audio play on channel 1");
      }
    }
    if (btnn == 1 && palyfunc[1] == "play") {
      if (window.mediaRecorder1.state == "inactive") {
        window.audioChunks1 = [];
        window.mediaRecorder1.start();
        // window.audio1.controls = false;
        SetPalyfunc((previousState) => {
          return { ...previousState, 1: "pause" };
        });
        console.log("audio pause on channel 1");
      }
    }
    if (btnn == 2 && palyfunc[2] == "pause") {
      // stop the palyback recoder
      if (window.mediaRecorder2) {
        console.log("recodring stop on channel 2");
        window.mediaRecorder2.stop();
        // change the status of recording
        // SetPalyfunc((previousState) => {
        //   return { ...previousState, 2: "play" };
        // });
        console.log("audio play on channel 2");
      }
    }
    if (btnn == 2 && palyfunc[2] == "play") {
      if (window.mediaRecorder2.state == "inactive") {
        window.audioChunks2 = [];
        window.mediaRecorder2.start();
        // window.audio2.controls = false;
        SetPalyfunc((previousState) => {
          return { ...previousState, 2: "pause" };
        });
        console.log("audio pause on channel 2");
      }
    }
    if (btnn == 3 && palyfunc[3] == "pause") {
      // stop the palyback recoder
      if (window.mediaRecorder3) {
        console.log("recodring stop on channel 3");
        window.mediaRecorder3.stop();
        // change the status of recording
        // SetPalyfunc((previousState) => {
        //   return { ...previousState, 3: "play" };
        // });
        console.log("audio play on channel 3");
      }
    }
    if (btnn == 3 && palyfunc[3] == "play") {
      if (window.mediaRecorder3.state == "inactive") {
        window.audioChunks3 = [];
        window.mediaRecorder3.start();
        // window.audio3.controls = false;
        SetPalyfunc((previousState) => {
          return { ...previousState, 3: "pause" };
        });
        console.log("audio pause on channel 3");
      }
    }
    if (btnn == 4 && palyfunc[4] == "pause") {
      // stop the palyback recoder
      if (window.mediaRecorder4) {
        console.log("recodring stop on channel 4");
        window.mediaRecorder4.stop();
        // change the status of recording
        // SetPalyfunc((previousState) => {
        //   return { ...previousState, 4: "play" };
        // });
        console.log("audio play on channel 4");
      }
    }
    if (btnn == 4 && palyfunc[4] == "play") {
      if (window.mediaRecorder4.state == "inactive") {
        window.audioChunks4 = [];
        window.mediaRecorder4.start();
        // window.audio4.controls = false;
        SetPalyfunc((previousState) => {
          return { ...previousState, 4: "pause" };
        });
        console.log("audio pause on channel 4");
      }
    }
    if (btnn == 5 && palyfunc[5] == "pause") {
      // stop the palyback recoder
      if (window.mediaRecorder5) {
        console.log("recodring stop on channel 5");
        window.mediaRecorder5.stop();
        // change the status of recording
        // SetPalyfunc((previousState) => {
        //   return { ...previousState, 5: "play" };
        // });
        console.log("audio play on channel 5");
      }
    }
    if (btnn == 5 && palyfunc[5] == "play") {
      if (window.mediaRecorder5.state == "inactive") {
        window.audioChunks5 = [];
        window.mediaRecorder5.start();
        // window.audio5.controls = false;
        SetPalyfunc((previousState) => {
          return { ...previousState, 5: "pause" };
        });
        console.log("audio pause on channel 5");
      }
    }
    if (btnn == 6 && palyfunc[6] == "pause") {
      // stop the palyback recoder
      if (window.mediaRecorder6) {
        console.log("recodring stop on channel 6");
        window.mediaRecorder6.stop();
        // change the status of recording
        // SetPalyfunc((previousState) => {
        //   return { ...previousState, 6: "play" };
        // });
        console.log("audio play on channel 6");
      }
    }
    if (btnn == 6 && palyfunc[6] == "play") {
      if (window.mediaRecorder6.state == "inactive") {
        window.audioChunks6 = [];
        window.mediaRecorder6.start();
        // window.audio6.controls = false;
        SetPalyfunc((previousState) => {
          return { ...previousState, 6: "pause" };
        });
        console.log("audio pause on channel 6");
      }
    }
    if (btnn == 7 && palyfunc[7] == "pause") {
      // stop the palyback recoder
      if (window.mediaRecorder7) {
        console.log("recodring stop on channel 7");
        window.mediaRecorder7.stop();
        // change the status of recording
        // SetPalyfunc((previousState) => {
        //   return { ...previousState, 7: "play" };
        // });
        console.log("audio play on channel 7");
      }
    }
    if (btnn == 7 && palyfunc[7] == "play") {
      if (window.mediaRecorder7.state == "inactive") {
        window.audioChunks7 = [];
        window.mediaRecorder7.start();
        // window.audio7.controls = false;
        SetPalyfunc((previousState) => {
          return { ...previousState, 7: "pause" };
        });
        console.log("audio pause on channel 7");
      }
    }
  };

  const toggleapi = () => {
    let session_separator_usrname = localStorage.getItem(
      "session_separator_usrname"
    );

    console.log("toggle api grop edit user", session_separator_usrname);
    if (statee[8] == "Active") {
      let grpdata = {
        user: session_separator_usrname,
        grp: 1,
        channel: micg,
      };
      dispatch(gropedit(grpdata));
      console.log(
        "follow button added in grop 1\n" +
          micg[1] +
          "\n" +
          micg[2] +
          "\n" +
          micg[3] +
          "\n" +
          micg[4] +
          "\n" +
          micg[5] +
          "\n" +
          micg[6] +
          "\n" +
          micg[7]
      );
      if (micg[1] == "Mute") {
        setGrup((previousState) => {
          return { ...previousState, 1: 1 };
        });
      }
      if (micg[2] == "Mute") {
        setGrup((previousState) => {
          return { ...previousState, 2: 1 };
        });
      }
      if (micg[3] == "Mute") {
        setGrup((previousState) => {
          return { ...previousState, 3: 1 };
        });
      }
      if (micg[4] == "Mute") {
        setGrup((previousState) => {
          return { ...previousState, 4: 1 };
        });
      }
      if (micg[5] == "Mute") {
        setGrup((previousState) => {
          return { ...previousState, 5: 1 };
        });
      }
      if (micg[6] == "Mute") {
        setGrup((previousState) => {
          return { ...previousState, 6: 1 };
        });
      }
      if (micg[7] == "Mute") {
        setGrup((previousState) => {
          return { ...previousState, 7: 1 };
        });
      }
    }
    if (statee[9] == "Active") {
      let grpdata = {
        user: session_separator_usrname,
        grp: 2,
        channel: micg,
      };
      dispatch(gropedit(grpdata));
      console.log(
        "follow button added in grop 2\n" +
          micg[1] +
          "\n" +
          micg[2] +
          "\n" +
          micg[3] +
          "\n" +
          micg[4] +
          "\n" +
          micg[5] +
          "\n" +
          micg[6] +
          "\n" +
          micg[7]
      );
      if (micg[1] == "Mute") {
        setGrup((previousState) => {
          return { ...previousState, 1: 2 };
        });
      }
      if (micg[2] == "Mute") {
        setGrup((previousState) => {
          return { ...previousState, 2: 2 };
        });
      }
      if (micg[3] == "Mute") {
        setGrup((previousState) => {
          return { ...previousState, 3: 2 };
        });
      }
      if (micg[4] == "Mute") {
        setGrup((previousState) => {
          return { ...previousState, 4: 2 };
        });
      }
      if (micg[5] == "Mute") {
        setGrup((previousState) => {
          return { ...previousState, 5: 2 };
        });
      }
      if (micg[6] == "Mute") {
        setGrup((previousState) => {
          return { ...previousState, 6: 2 };
        });
      }
      if (micg[7] == "Mute") {
        setGrup((previousState) => {
          return { ...previousState, 7: 2 };
        });
      }
    }
  };

  //this is use for edit the channel for group
  const togglegropedit = (btnn) => {
    console.log("toggle grop edit ", micg[btnn], btnn);

    if (btnn == 1 && micg[1] == "Mute") {
      console.log("micg 1 is mute");
      setMicg((previousState) => {
        return { ...previousState, 1: "Unmute" };
      });
    }
    if (btnn == 1 && micg[1] == "Unmute") {
      console.log("micg 1 is Unmute");
      setMicg((previousState) => {
        return { ...previousState, 1: "Mute" };
      });
    }
    if (btnn == 2 && micg[2] == "Mute") {
      console.log("micg 2 is mute");
      setMicg((previousState) => {
        return { ...previousState, 2: "Unmute" };
      });
    }
    if (btnn == 2 && micg[2] == "Unmute") {
      console.log("micg 2 is Unmute");
      setMicg((previousState) => {
        return { ...previousState, 2: "Mute" };
      });
    }
    if (btnn == 3 && micg[3] == "Mute") {
      console.log("micg 3 is mute");
      setMicg((previousState) => {
        return { ...previousState, 3: "Unmute" };
      });
    }
    if (btnn == 3 && micg[3] == "Unmute") {
      console.log("micg 3 is Unmute");
      setMicg((previousState) => {
        return { ...previousState, 3: "Mute" };
      });
    }
    if (btnn == 4 && micg[4] == "Mute") {
      console.log("micg 4 is mute");
      setMicg((previousState) => {
        return { ...previousState, 4: "Unmute" };
      });
    }
    if (btnn == 4 && micg[4] == "Unmute") {
      console.log("micg 4 is Unmute");
      setMicg((previousState) => {
        return { ...previousState, 4: "Mute" };
      });
    }
    if (btnn == 5 && micg[5] == "Mute") {
      console.log("micg 5 is mute");
      setMicg((previousState) => {
        return { ...previousState, 5: "Unmute" };
      });
    }
    if (btnn == 5 && micg[5] == "Unmute") {
      console.log("micg 5 is Unmute");
      setMicg((previousState) => {
        return { ...previousState, 5: "Mute" };
      });
    }
    if (btnn == 6 && micg[6] == "Mute") {
      console.log("micg 6 is mute");
      setMicg((previousState) => {
        return { ...previousState, 6: "Unmute" };
      });
    }
    if (btnn == 6 && micg[6] == "Unmute") {
      console.log("micg 6 is Unmute");
      setMicg((previousState) => {
        return { ...previousState, 6: "Mute" };
      });
    }
    if (btnn == 7 && micg[7] == "Mute") {
      console.log("micg 7 is mute");
      setMicg((previousState) => {
        return { ...previousState, 7: "Unmute" };
      });
    }
    if (btnn == 7 && micg[7] == "Unmute") {
      console.log("micg 7 is Unmute");
      setMicg((previousState) => {
        return { ...previousState, 7: "Mute" };
      });
    }
  };
  // this toggle manage the mic mute functioanlty for all channels on click
  // mic1state and all other variable use to hold the latched statee
  const toggleupdate = (btnn) => {
    console.log("this is the ", mic[btnn], btnn);

    if (btnn == 1 && mic[1] == "Mute") {
      console.log("mic 1 is mute");
      window.mic1.mute({ audio: true });
      window.mic1state = true;
      setMic((previousState) => {
        return { ...previousState, 1: "Unmute" };
      });
    }
    if (btnn == 1 && mic[1] == "Unmute") {
      console.log("mic 1 is Unmute");
      if (window.calldata_1 != undefined) {
        window.mic1.unmute({ audio: true });
        window.mic1state = false;
        setMic((previousState) => {
          return { ...previousState, 1: "Mute" };
        });
      }
    }
    if (btnn == 2 && mic[2] == "Mute") {
      console.log("mic 2 is mute");
      window.mic2.mute({ audio: true });
      window.mic2state = true;
      setMic((previousState) => {
        return { ...previousState, 2: "Unmute" };
      });
    }
    if (btnn == 2 && mic[2] == "Unmute") {
      console.log("mic 2 is Unmute");
      if (window.calldata_2 != undefined) {
        window.mic2.unmute({ audio: true });
        window.mic2state = false;
        setMic((previousState) => {
          return { ...previousState, 2: "Mute" };
        });
      }
    }
    if (btnn == 3 && mic[3] == "Mute") {
      console.log("mic 3 is mute");
      window.mic3.mute({ audio: true });
      window.mic3state = true;
      setMic((previousState) => {
        return { ...previousState, 3: "Unmute" };
      });
    }
    if (btnn == 3 && mic[3] == "Unmute") {
      console.log("mic 3 is Unmute");
      if (window.calldata_3 != undefined) {
        window.mic3.unmute({ audio: true });
        window.mic3state = false;
        setMic((previousState) => {
          return { ...previousState, 3: "Mute" };
        });
      }
    }
    if (btnn == 4 && mic[4] == "Mute") {
      console.log("mic 4 is mute");
      window.mic4.mute({ audio: true });
      window.mic4state = true;
      setMic((previousState) => {
        return { ...previousState, 4: "Unmute" };
      });
    }
    if (btnn == 4 && mic[4] == "Unmute") {
      console.log("mic 4 is Unmute");
      if (window.calldata_4 != undefined) {
        window.mic4.unmute({ audio: true });
        window.mic4state = false;
        setMic((previousState) => {
          return { ...previousState, 4: "Mute" };
        });
      }
    }
    if (btnn == 5 && mic[5] == "Mute") {
      console.log("mic 5 is mute");
      window.mic5.mute({ audio: true });
      window.mic5state = true;
      setMic((previousState) => {
        return { ...previousState, 5: "Unmute" };
      });
    }
    if (btnn == 5 && mic[5] == "Unmute") {
      console.log("mic 5 is Unmute");
      if (window.calldata_5 != undefined) {
        window.mic5.unmute({ audio: true });
        window.mic5state = false;
        setMic((previousState) => {
          return { ...previousState, 5: "Mute" };
        });
      }
    }
    if (btnn == 6 && mic[6] == "Mute") {
      console.log("mic 6 is mute");
      window.mic6.mute({ audio: true });
      window.mic6state = true;
      setMic((previousState) => {
        return { ...previousState, 6: "Unmute" };
      });
    }
    if (btnn == 6 && mic[6] == "Unmute") {
      console.log("mic 6 is Unmute");
      if (window.calldata_6 != undefined) {
        window.mic6.unmute({ audio: true });
        window.mic6state = false;
        setMic((previousState) => {
          return { ...previousState, 6: "Mute" };
        });
      }
    }
    if (btnn == 7 && mic[7] == "Mute") {
      console.log("mic 7 is mute");
      window.mic7.mute({ audio: true });
      window.mic7state = true;
      setMic((previousState) => {
        return { ...previousState, 7: "Unmute" };
      });
    }
    if (btnn == 7 && mic[7] == "Unmute") {
      console.log("mic 7 is Unmute");
      if (window.calldata_7 != undefined) {
        window.mic7.unmute({ audio: true });
        window.mic7state = false;
        setMic((previousState) => {
          return { ...previousState, 7: "Mute" };
        });
      }
    }
  };
  const toggleupdate1 = (btnn) => {
    console.log("this again is the ", mic[btnn], btnn);

    if (btnn == 1 && micagain[1] == "Mute") {
      console.log("micagain 1 is mute");
      window.mic1.mute({ audio: true });
      window.mic1state = true;
      setMicagain((previousState) => {
        return { ...previousState, 1: "Unmute" };
      });
    }
    if (btnn == 1 && micagain[1] == "Unmute") {
      console.log("micagain 1 is Unmute");
      if (window.calldata_1 != undefined) {
        window.mic1.unmute({ audio: true });
        window.mic1state = false;
        setMicagain((previousState) => {
          return { ...previousState, 1: "Mute" };
        });
      }
    }
    if (btnn == 2 && micagain[2] == "Mute") {
      console.log("micagain 2 is mute");
      window.mic2.mute({ audio: true });
      window.mic2state = true;
      setMicagain((previousState) => {
        return { ...previousState, 2: "Unmute" };
      });
    }
    if (btnn == 2 && micagain[2] == "Unmute") {
      console.log("micagain 2 is Unmute");
      if (window.calldata_2 != undefined) {
        window.mic2.unmute({ audio: true });
        window.mic2state = false;
        setMicagain((previousState) => {
          return { ...previousState, 2: "Mute" };
        });
      }
    }
    if (btnn == 3 && micagain[3] == "Mute") {
      console.log("micagain 3 is mute");
      window.mic3.mute({ audio: true });
      window.mic3state = true;
      setMicagain((previousState) => {
        return { ...previousState, 3: "Unmute" };
      });
    }
    if (btnn == 3 && micagain[3] == "Unmute") {
      console.log("micagain 3 is Unmute");
      if (window.calldata_3 != undefined) {
        window.mic3.unmute({ audio: true });
        window.mic3state = false;
        setMicagain((previousState) => {
          return { ...previousState, 3: "Mute" };
        });
      }
    }
    if (btnn == 4 && micagain[4] == "Mute") {
      console.log("micagain 4 is mute");
      window.mic4.mute({ audio: true });
      window.mic4state = true;
      setMicagain((previousState) => {
        return { ...previousState, 4: "Unmute" };
      });
    }
    if (btnn == 4 && micagain[4] == "Unmute") {
      console.log("micagain 4 is Unmute");
      if (window.calldata_4 != undefined) {
        window.mic4.unmute({ audio: true });
        window.mic4state = false;
        setMicagain((previousState) => {
          return { ...previousState, 4: "Mute" };
        });
      }
    }
    if (btnn == 5 && micagain[5] == "Mute") {
      console.log("micagain 5 is mute");
      window.mic5.mute({ audio: true });
      window.mic5state = true;
      setMicagain((previousState) => {
        return { ...previousState, 5: "Unmute" };
      });
    }
    if (btnn == 5 && micagain[5] == "Unmute") {
      console.log("micagain 5 is Unmute");
      if (window.calldata_5 != undefined) {
        window.mic5.unmute({ audio: true });
        window.mic5state = false;
        setMicagain((previousState) => {
          return { ...previousState, 5: "Mute" };
        });
      }
    }
    if (btnn == 6 && micagain[6] == "Mute") {
      console.log("micagain 6 is mute");
      window.mic6.mute({ audio: true });
      window.mic6state = true;
      setMicagain((previousState) => {
        return { ...previousState, 6: "Unmute" };
      });
    }
    if (btnn == 6 && micagain[6] == "Unmute") {
      console.log("micagain 6 is Unmute");
      if (window.calldata_6 != undefined) {
        window.mic6.unmute({ audio: true });
        window.mic6state = false;
        setMicagain((previousState) => {
          return { ...previousState, 6: "Mute" };
        });
      }
    }
    if (btnn == 7 && micagain[7] == "Mute") {
      console.log("micagain 7 is mute");
      window.mic7.mute({ audio: true });
      window.mic7state = true;
      setMicagain((previousState) => {
        return { ...previousState, 7: "Unmute" };
      });
    }
    if (btnn == 7 && micagain[7] == "Unmute") {
      console.log("micagain 7 is Unmute");
      if (window.calldata_7 != undefined) {
        window.mic7.unmute({ audio: true });
        window.mic7state = false;
        setMicagain((previousState) => {
          return { ...previousState, 7: "Mute" };
        });
      }
    }
  };

  // this volume funcation will call on click
  const valum = async (position, channel, spk_test) => {
    let session_separator_usrname = localStorage.getItem(
      "session_separator_usrname"
    );
    console.log("this is for spk position ", position);
    console.log("this is for spk channel ", channel);
    console.log("this is for spk volum ", spk_test);
    //   this switch is using for real time voulume bar audjestment and volume adjuestment
    switch (position) {
      case 1:
        if (window.audio_v1) {
          window.audio_v1.volume = spk_test / 100;
          setVolum((previousState) => {
            return { ...previousState, 1: spk_test };
          });
        }
        break;
      case 2:
        if (window.audio_v2) {
          window.audio_v2.volume = spk_test / 100;
          setVolum((previousState) => {
            return { ...previousState, 2: spk_test };
          });
        }
        break;
      case 3:
        if (window.audio_v3) {
          window.audio_v3.volume = spk_test / 100;
          setVolum((previousState) => {
            return { ...previousState, 3: spk_test };
          });
        }
        break;
      case 4:
        if (window.audio_v4) {
          window.audio_v4.volume = spk_test / 100;
          setVolum((previousState) => {
            return { ...previousState, 4: spk_test };
          });
        }
        break;
      case 5:
        if (window.audio_v5) {
          window.audio_v5.volume = spk_test / 100;
          setVolum((previousState) => {
            return { ...previousState, 5: spk_test };
          });
        }
        break;
      case 6:
        if (window.audio_v6) {
          window.audio_v6.volume = spk_test / 100;
          setVolum((previousState) => {
            return { ...previousState, 6: spk_test };
          });
        }
        break;
      case 7:
        if (window.audio_v7) {
          window.audio_v7.volume = spk_test / 100;
          setVolum((previousState) => {
            return { ...previousState, 7: spk_test };
          });
        }
        break;
    }
    let datavol = {
      user: session_separator_usrname,
      channel: channel,
      volumeval: spk_test,
    };
    if (
      window.audio_v1 ||
      window.audio_v2 ||
      window.audio_v3 ||
      window.audio_v4 ||
      window.audio_v5 ||
      window.audio_v6 ||
      window.audio_v7
    ) {
      setTimeout(() => {
        console.log("this is for spk done api ");
        // hit this api with 7 second delay to write the volume in file which is located on server
        dispatch(volumeControll(datavol));
      }, 7000);
    }
  };
  var spk_test1 = 50;
  //   this updaterange will continuesly update on darge the volume slider
  const updateRange = (e, data) => {
    spk_test1 = e.target.value;
  };
  // this handleclick method is use to manage the group button state
  const handleClick = (btn, ardata) => {
    window.statev = 0;

    var i;
    if (btn == 8 && statee[8] == "Inactive") {
      // these forloop are used to manage the latched state , it should open and close on mouse button up and down
      for (i = 1; i < 8; i++) {
        if (ardata[i] == 1) {
          toggleupdate(i);
        }
        if (statee[10] == "Active") {
          toggleupdate1(i);
        }
      }
      window.statev = 1;
      setTimeout(() => {
        if (window.statev == 1) {
          setStatetimer((previousState) => {
            return { ...previousState, 8: "Active" };
          });
        }
        console.log("state is", window.statev);
      }, 250);
      setStatee((previousState) => {
        return { ...previousState, 8: "Active" };
      });
      if (statee[10] == "Active") {
        setStatetimer((previousState) => {
          return { ...previousState, 10: "Inactive" };
        });
        setStatee((previousState) => {
          return { ...previousState, 10: "Inactive" };
        });
      }
    } else if (btn == 8 && statee[8] == "Active") {
      for (i = 1; i < 8; i++) {
        if (ardata[i] == 1) {
          toggleupdate(i);
        }
      }
      window.statev = 0;
      setStatetimer((previousState) => {
        return { ...previousState, 8: "Inactive" };
      });
      setStatee((previousState) => {
        return { ...previousState, 8: "Inactive" };
      });
    }
    if (btn == 9 && statee[9] == "Inactive") {
      for (i = 1; i < 8; i++) {
        if (ardata[i] == 2) {
          toggleupdate(i);
        }
        if (statee[10] == "Active") {
          toggleupdate1(i);
        }
      }
      window.statev = 1;
      setTimeout(() => {
        if (window.statev == 1) {
          setStatetimer((previousState) => {
            return { ...previousState, 9: "Active" };
          });
        }
        console.log("state is", window.statev);
      }, 250);
      setStatee((previousState) => {
        return { ...previousState, 9: "Active" };
      });
      if (statee[10] == "Active") {
        setStatetimer((previousState) => {
          return { ...previousState, 10: "Inactive" };
        });
        setStatee((previousState) => {
          return { ...previousState, 10: "Inactive" };
        });
      }
    } else if (btn == 9 && statee[9] == "Active") {
      for (i = 1; i < 8; i++) {
        if (ardata[i] == 2) {
          toggleupdate(i);
        }
      }
      window.statev = 0;
      setStatetimer((previousState) => {
        return { ...previousState, 9: "Inactive" };
      });
      setStatee((previousState) => {
        return { ...previousState, 9: "Inactive" };
      });
    }
    if (btn == 10 && statee[10] == "Inactive") {
      for (i = 1; i < 8; i++) {
        toggleupdate1(i);
        if (statee[8] == "Active" && ardata[i] == 1) {
          toggleupdate(i);
        }
        if (statee[9] == "Active" && ardata[i] == 2) {
          toggleupdate(i);
        }
      }
      window.statev = 1;
      setTimeout(() => {
        if (window.statev == 1) {
          setStatetimer((previousState) => {
            return { ...previousState, 10: "Active" };
          });
        }
        console.log("state is", window.statev);
      }, 250);
      setStatee((previousState) => {
        return { ...previousState, 10: "Active" };
      });
      if (statee[8] == "Active") {
        setStatetimer((previousState) => {
          return { ...previousState, 8: "Inactive" };
        });
        setStatee((previousState) => {
          return { ...previousState, 8: "Inactive" };
        });
      }
      if (statee[9] == "Active") {
        setStatetimer((previousState) => {
          return { ...previousState, 9: "Inactive" };
        });
        setStatee((previousState) => {
          return { ...previousState, 9: "Inactive" };
        });
      }
    } else if (btn == 10 && statee[10] == "Active") {
      for (i = 1; i < 8; i++) {
        toggleupdate1(i);
      }
      window.statev = 0;
      setStatetimer((previousState) => {
        return { ...previousState, 10: "Inactive" };
      });
      setStatee((previousState) => {
        return { ...previousState, 10: "Inactive" };
      });
    }
    if (btn == 11 && statee[11] == "Inactive") {
      setStatee((previousState) => {
        return { ...previousState, 11: "Active" };
      });
    } else if (btn == 11 && statee[11] == "Active") {
      toggleapi();
      handleClosewarn();
      handleClose();
      setStatee((previousState) => {
        return { ...previousState, 8: "Inactive" };
      });
      setStatee((previousState) => {
        return { ...previousState, 9: "Inactive" };
      });
      setMicg((previousState) => {
        return { ...previousState, 1: "Unmute" };
      });
      setMicg((previousState) => {
        return { ...previousState, 2: "Unmute" };
      });
      setMicg((previousState) => {
        return { ...previousState, 3: "Unmute" };
      });
      setMicg((previousState) => {
        return { ...previousState, 4: "Unmute" };
      });
      setMicg((previousState) => {
        return { ...previousState, 5: "Unmute" };
      });
      setMicg((previousState) => {
        return { ...previousState, 6: "Unmute" };
      });
      setMicg((previousState) => {
        return { ...previousState, 7: "Unmute" };
      });
      setStatee((previousState) => {
        return { ...previousState, 11: "Inactive" };
      });
    }
  };

  const handlegroupedit = (btn, ardata) => {
    var i;
    if (btn == 8 && statee[8] == "Inactive") {
      handleShowwarn();
      // these forloop are used to manage the latched state , it should open and close on mouse button up and down
      for (i = 1; i < 8; i++) {
        if (ardata[i] == 1) {
          togglegropedit(i);
        }
        if (statee[9] == "Active" && ardata[i] == 2) {
          togglegropedit(i);
        }
      }
      setStatee((previousState) => {
        return { ...previousState, 8: "Active" };
      });
      if (statee[9] == "Active") {
        setStatee((previousState) => {
          return { ...previousState, 9: "Inactive" };
        });
      }
    } else if (btn == 8 && statee[8] == "Active") {
      for (i = 1; i < 8; i++) {
        if (ardata[i] == 1) {
          togglegropedit(i);
        }
      }
      setStatee((previousState) => {
        return { ...previousState, 8: "Inactive" };
      });
    }
    if (btn == 9 && statee[9] == "Inactive") {
      handleShowwarn();
      for (i = 1; i < 8; i++) {
        if (ardata[i] == 2) {
          togglegropedit(i);
        }
        if (statee[8] == "Active" && ardata[i] == 1) {
          togglegropedit(i);
        }
      }
      setStatee((previousState) => {
        return { ...previousState, 9: "Active" };
      });
      if (statee[8] == "Active") {
        setStatee((previousState) => {
          return { ...previousState, 8: "Inactive" };
        });
      }
    } else if (btn == 9 && statee[9] == "Active") {
      for (i = 1; i < 8; i++) {
        if (ardata[i] == 2) {
          togglegropedit(i);
        }
      }
      setStatee((previousState) => {
        return { ...previousState, 9: "Inactive" };
      });
    }
    if (btn == 10 && statee[10] == "Inactive") {
      // for (i=1; i<8; i++) {
      //     togglegropedit(i);
      // }
      // setStatee(previousState => {
      //     return { ...previousState, 10: "Active" }
      // });
    } else if (btn == 10 && statee[10] == "Active") {
      // for (i=1; i<8; i++) {
      //     togglegropedit(i);
      // }
      // setStatee(previousState => {
      //     return { ...previousState, 10: "Inactive" }
      // });
    }
    if (btn == 11 && statee[11] == "Inactive") {
      setStatee((previousState) => {
        return { ...previousState, 11: "Active" };
      });
    } else if (btn == 11 && statee[11] == "Active") {
      setStatee((previousState) => {
        return { ...previousState, 11: "Inactive" };
      });
    }
  };

  useEffect(() => {
    let i = 1;
    let va = 0,
      vadforarray = 0;
    var audioArray = new Array();
    var stringArray = new Array();
    var callchannel = new Array();
    var calldata = new Array();
    let curr = Array.from(Array(26).keys()).map((e) => ["", "", "", "", ""]); // Generate ["Name", "Number"] * 11 to offset index by 1
    window.extchannel = {};
    window.extseq = [];

    for (i = 1; i < 8; i++) {
      let channel = "CH" + i;

      props.dialButtons.forEach((e) => {
        if (e[0] == channel) {
          window.extseq.push(e[0] + "|" + e[1]);
          console.log(
            "this is test i cahnnel data ",
            e[0],
            " ",
            channel,
            " and this is confrence data ",
            e[1]
          );

          console.log("this is test i number ", e[4]);
          // this switch is use to manage the volume state on login and on reload
          switch (i) {
            case 1:
              if (e[3] == undefined || e[3] == "") {
                setVolum((previousState) => {
                  return { ...previousState, 1: 90 };
                });
              } else {
                setVolum((previousState) => {
                  return { ...previousState, 1: e[3] };
                });
              }
              if (e[4] == undefined || e[4] == "") {
                setGrup((previousState) => {
                  return { ...previousState, 1: 1 };
                });
              } else {
                setGrup((previousState) => {
                  return { ...previousState, 1: e[4] };
                });
              }
              break;
            case 2:
              if (e[3] == undefined || e[3] == "") {
                setVolum((previousState) => {
                  return { ...previousState, 2: 90 };
                });
              } else {
                setVolum((previousState) => {
                  return { ...previousState, 2: e[3] };
                });
              }
              if (e[4] == undefined || e[4] == "") {
                setGrup((previousState) => {
                  return { ...previousState, 2: 1 };
                });
              } else {
                setGrup((previousState) => {
                  return { ...previousState, 2: e[4] };
                });
              }
              break;
            case 3:
              if (e[3] == undefined || e[3] == "") {
                setVolum((previousState) => {
                  return { ...previousState, 3: 90 };
                });
              } else {
                setVolum((previousState) => {
                  return { ...previousState, 3: e[3] };
                });
              }
              if (e[4] == undefined || e[4] == "") {
                setGrup((previousState) => {
                  return { ...previousState, 3: 1 };
                });
              } else {
                setGrup((previousState) => {
                  return { ...previousState, 3: e[4] };
                });
              }
              break;
            case 4:
              if (e[3] == undefined || e[3] == "") {
                setVolum((previousState) => {
                  return { ...previousState, 4: 90 };
                });
              } else {
                setVolum((previousState) => {
                  return { ...previousState, 4: e[3] };
                });
              }
              if (e[4] == undefined || e[4] == "") {
                setGrup((previousState) => {
                  return { ...previousState, 4: 1 };
                });
              } else {
                setGrup((previousState) => {
                  return { ...previousState, 4: e[4] };
                });
              }
              break;
            case 5:
              if (e[3] == undefined || e[3] == "") {
                setVolum((previousState) => {
                  return { ...previousState, 5: 90 };
                });
              } else {
                setVolum((previousState) => {
                  return { ...previousState, 5: e[3] };
                });
              }
              if (e[4] == undefined || e[4] == "") {
                setGrup((previousState) => {
                  return { ...previousState, 5: 1 };
                });
              } else {
                setGrup((previousState) => {
                  return { ...previousState, 5: e[4] };
                });
              }
              break;
            case 6:
              if (e[3] == undefined || e[3] == "") {
                setVolum((previousState) => {
                  return { ...previousState, 6: 90 };
                });
              } else {
                setVolum((previousState) => {
                  return { ...previousState, 6: e[3] };
                });
              }
              if (e[4] == undefined || e[4] == "") {
                setGrup((previousState) => {
                  return { ...previousState, 6: 1 };
                });
              } else {
                setGrup((previousState) => {
                  return { ...previousState, 6: e[4] };
                });
              }
              break;
            case 7:
              if (e[3] == undefined || e[3] == "") {
                setVolum((previousState) => {
                  return { ...previousState, 7: 90 };
                });
              } else {
                setVolum((previousState) => {
                  return { ...previousState, 7: e[3] };
                });
              }
              if (e[4] == undefined || e[4] == "") {
                setGrup((previousState) => {
                  return { ...previousState, 7: 1 };
                });
              } else {
                setGrup((previousState) => {
                  return { ...previousState, 7: e[4] };
                });
              }
              break;
          }
          // this micval is use in userReduce page to manage the mic value for mute and unmute
          window.micval = i;
          vadforarray++;
          callchannel[vadforarray] = i;
          window.uaa.start();
          curr[parseInt([i])] = [e[0], e[1], e[2], e[3], e[4]];
          console.log("Call init on Channel number : " + i);
          console.log("Call init on confrence number: " + e[1]);
          calldata[i] = e[1];
          window.calldata_1 = calldata[1];
          window.calldata_2 = calldata[2];
          window.calldata_3 = calldata[3];
          window.calldata_4 = calldata[4];
          window.calldata_5 = calldata[5];
          window.calldata_6 = calldata[6];
          window.calldata_7 = calldata[7];
          
          var session = window.uaa.call(
            e[1] + (process.env.REACT_APP_FREEPBX_URI),
            window.call_option
          );
          if (session) {
            console.log("this is showing in session : ", session.connection);
            if (session.connection.addTrack !== undefined) {
              session.connection.ontrack = (ev) => {
                console.log("addtrack if is runign " + ev.streams[0]);

                var audio = document.createElement("audio");
                audio.srcObject = ev.streams[0];
                console.log("s dot stream value " + ev.streams[0]);

                audio.play();
                va++;

                console.log("outer voluem of each instance " + audio.volume);
                console.log(
                  "this is inner i value " + callchannel[va] + " " + va
                );
                // below is used to manage the actual volume fncationalty
                audioArray[callchannel[va]] = audio;
                window.audio_v1 = audioArray[1];
                window.audio_v2 = audioArray[2];
                window.audio_v3 = audioArray[3];
                window.audio_v4 = audioArray[4];
                window.audio_v5 = audioArray[5];
                window.audio_v6 = audioArray[6];
                window.audio_v7 = audioArray[7];
                // this switch is use to controll the volume real time
                switch (callchannel[va]) {
                  case 1:
                    console.log("this 1 is test e[3] number ", e[3]);
                    if (e[3] == undefined || e[3] == "") {
                      window.audio_v1.volume = 90 / 100;
                    } else {
                      window.audio_v1.volume = e[3] / 100;
                    }
                    break;
                  case 2:
                    console.log("this 2 is test e[3] number ", e[3]);
                    if (e[3] == undefined || e[3] == "") {
                      window.audio_v2.volume = 90 / 100;
                    } else {
                      window.audio_v2.volume = e[3] / 100;
                    }
                    break;
                  case 3:
                    console.log("this 3 is test e[3] number ", e[3]);
                    if (e[3] == undefined || e[3] == "") {
                      window.audio_v3.volume = 90 / 100;
                    } else {
                      window.audio_v3.volume = e[3] / 100;
                    }
                    break;
                  case 4:
                    console.log("this 4 is test e[3] number ", e[3]);
                    if (e[3] == undefined || e[3] == "") {
                      window.audio_v4.volume = 90 / 100;
                    } else {
                      window.audio_v4.volume = e[3] / 100;
                    }
                    break;
                  case 5:
                    console.log("this 5 is test e[3] number ", e[3]);
                    if (e[3] == undefined || e[3] == "") {
                      window.audio_v5.volume = 90 / 100;
                    } else {
                      window.audio_v5.volume = e[3] / 100;
                    }
                    break;
                  case 6:
                    console.log("this 6 is test e[3] number ", e[3]);
                    if (e[3] == undefined || e[3] == "") {
                      window.audio_v6.volume = 90 / 100;
                    } else {
                      window.audio_v6.volume = e[3] / 100;
                    }
                    break;
                  case 7:
                    console.log("this 7 is test e[3] number ", e[3]);
                    if (e[3] == undefined || e[3] == "") {
                      window.audio_v7.volume = 90 / 100;
                    } else {
                      window.audio_v7.volume = e[3] / 100;
                    }
                    break;
                }
                // below is use to manage the vadd funcationalty by passing the stream info
                console.log(callchannel[va], " ", ev.streams[0]);
                stringArray[callchannel[va]] = ev.streams[0];
                // window.steamchannel_1 = stringArray[1];
                // window.steamchannel_2 = stringArray[2];
                // window.steamchannel_3 = stringArray[3];
                // window.steamchannel_4 = stringArray[4];
                // window.steamchannel_5 = stringArray[5];
                // window.steamchannel_6 = stringArray[6];
                // window.steamchannel_7 = stringArray[7];

                stringArray[callchannel[va]] = ev.streams[0];

                window.extchannel[e[1]] = ev.streams[0];

                let indx = 1;
                window.extseq.forEach((element) => {
                  let elementArr = element.split("|");
                  switch (elementArr[0]) {
                    case "CH1":
                      window.steamchannel_1 = window.extchannel[elementArr[1]];
                      break;
                    case "CH2":
                      window.steamchannel_2 = window.extchannel[elementArr[1]];
                      break;
                    case "CH3":
                      window.steamchannel_3 = window.extchannel[elementArr[1]];
                      break;
                    case "CH4":
                      window.steamchannel_4 = window.extchannel[elementArr[1]];
                      break;
                    case "CH5":
                      window.steamchannel_5 = window.extchannel[elementArr[1]];
                      break;
                    case "CH6":
                      window.steamchannel_6 = window.extchannel[elementArr[1]];
                      break;
                    case "CH7":
                      window.steamchannel_7 = window.extchannel[elementArr[1]];
                      break;
                  }
                  indx++;
                });

                console.log(
                  "vad ch1 ",
                  window.steamchannel_1,
                  " vad ch2 ",
                  window.steamchannel_2,
                  " vad ch3 ",
                  window.steamchannel_3,
                  " vad ch4 ",
                  window.steamchannel_4,
                  " vad ch5 ",
                  window.steamchannel_5,
                  " vad ch6 ",
                  window.steamchannel_6,
                  " vad ch7 ",
                  window.steamchannel_7
                );
                if (va == 1) {
                  setTimeout(() => {
                    // this is using for initiate the vad funcationalty
                    // this mic set is use to just update the use effect to activa the vad funcationalty
                    setMic((previousState) => {
                      return { ...previousState, 7: "Unmute" };
                    });
                  }, 2000);
                }
              };
            } else {
              session.connection.onaddstream = (ev) => {
                console.log("addtrack else is runign");
              };
            }
          }
        }
      });
      setDialButtonList(curr);
    }
  }, [props.dialButtons, callData.buttons]);

  let doneButtons = [];

  return (
    <>
      {Array.from(Array(7).keys()).map((e) => {
        let button_number = e * 1 + 1; //e*2+1
        let group_button = e * 1 + 8;
        let volume_btn = e * 1 + 15;
        doneButtons.push(button_number);

        return (
          <Row key={e} className={`main-btn-tab`}>
            {/* this boyd is used for group buttons */}
            {group_button < 11 ? (
              <button
                key={group_button}
                className={`group-button`}
                style={
                  statee[group_button] == "Active"
                    ? { backgroundColor: "rgb(209, 108, 0)" }
                    : { Color: "black" }
                }
                onMouseDown={
                  statee[11] == "Active"
                    ? () => {
                        dummy();
                      }
                    : () => {
                        handleClick(group_button, grup);
                      }
                }
                onMouseUp={
                  statee[11] == "Active"
                    ? warn
                      ? () => {
                          handleShow();
                        }
                      : () => {
                          handlegroupedit(group_button, grup);
                        }
                    : statetimer[group_button] == "Active"
                    ? () => {
                        handleClick(group_button, grup);
                      }
                    : () => {
                        dummy();
                      }
                }
              >
                {"Grp"}
                <br />
                {grpbtn[group_button]}
              </button>
            ) : (
              ""
            )}
            {/* this body is use for group edit button */}
            {group_button == 11 ? (
              <button
                key={group_button}
                className={`group-button`}
                style={
                  statee[group_button] == "Active"
                    ? { backgroundColor: "rgb(209, 108, 0)" }
                    : { Color: "black" }
                }
                onClick={
                  DialButtonList
                    ? () => {
                        handleClick(group_button);
                      }
                    : () => {
                        dummy();
                      }
                }
              >
                {"Grp"}
                <br />
                {statee[11] == "Active" ? "Save" : "Edit"}
              </button>
            ) : (
              ""
            )}
            {group_button == 11 ? (
              <Modal show={show} onHide={handleClose}>
                <Modal.Header>
                  <Modal.Title>Group Edit Warning</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Oops... Must save before move on other grop!
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                  <Button
                    variant="success"
                    onClick={() => {
                      handleClick(group_button);
                    }}
                  >
                    Save Changes
                  </Button>
                </Modal.Footer>
              </Modal>
            ) : (
              ""
            )}

            <div
              key={button_number}
              className={`speaker-buuton ${
                DialButtonList[button_number][2]
                  ? callcondition[button_number] == "true"
                    ? "speaker-color"
                    : "disablecolor"
                  : "spearker-icon speaker-btn-color"
              } ${
                mic[button_number] == "Mute" ||
                micagain[button_number] == "Mute" ||
                micg[button_number] == "Mute"
                  ? "speaker-unmute"
                  : ""
              }`}
            >
              <button
                // className={`speed-dial-button `}
                className={`${
                  DialButtonList[button_number][2] ? "spk-btn-half" : ""
                }`}
                onClick={
                  statee[11] == "Active"
                    ? () => {
                        togglegropedit(button_number);
                      }
                    : callcondition[button_number] == "true"
                    ? () => {
                        toggleupdate(button_number);
                      }
                    : () => {
                        dummy();
                      }
                }
              >
                {DialButtonList[button_number][2]}
                <br />
                {DialButtonList[button_number][2] ? (
                  <span className="group-name">{grup[button_number]}</span>
                ) : (
                  ""
                )}
                {DialButtonList[button_number][2] ? (
                  vadd[button_number] == "Voice_Not_Active" ? (
                    callcondition[button_number] == "true" ? (
                      <input
                        type="range"
                        min="0"
                        max="100"
                        step="5"
                        value={volum[button_number]}
                        onChange={updateRange}
                        onTouchEnd={
                          statee[11] == "Active"
                            ? () => {
                                togglegropedit(button_number);
                              }
                            : callcondition[button_number] == "true"
                            ? () => {
                                toggleupdate(button_number);
                              }
                            : () => {
                                dummy();
                              }
                        }
                      />
                    ) : (
                      ""
                    )
                  ) : vaddfluc[button_number] == "vad_Fluctuat" ? (
                    <img className="bar1" src={vbar} alt=""></img>
                  ) : (
                    <img className="bar1" src={statticpic} alt=""></img>
                  )
                ) : (
                  ""
                )}

                {/* <br />{DialButtonList[button_number][1]} */}
              </button>

              <span className="speaker-number">{button_number}</span>
            </div>

            <div key={volume_btn} className="vlum-flex">
              <button
                className={`volume-btn ${
                  palyfunc[volume_btn - 14] == "pause"
                    ? "player_play"
                    : "player_stop"
                }`}
                onClick={
                  callcondition[button_number] == "true"
                    ? () => {
                      blurvv(1);
                      paly_player(volume_btn - 14);
                      }
                    : () => {
                        dummy();
                      }
                }
              ></button>
              <button
                className={`volume-btn volume_down`}
                onClick={
                  callcondition[button_number] == "true"
                    ? () => {
                        volume_down(volume_btn - 14);
                      }
                    : () => {
                        dummy();
                      }
                }
              ></button>
              <button
                className={`volume-btn volume_up`}
                onClick={
                  callcondition[button_number] == "true"
                    ? () => {
                        volume_up(volume_btn - 14);
                      }
                    : () => {
                        dummy();
                      }
                }
              ></button>

              <Modal
                show={palyfunc[volume_btn - 14] == "pause" ? false : true}
                centered
                id="waveModal"
                onExited={hidePlayerModal}
              >
                <Modal.Body>
                  <WavePlayer
                    url={recurl[volume_btn - 14]}
                    ref={wavePlayerRef}
                    channelName={DialButtonList[button_number][2]}
                  />
                  {/* <WavePlayer
                    url="https://www.mfiles.co.uk/mp3-downloads/gs-cd-track2.mp3"
                    channelName={DialButtonList[button_number][2]}
                    ref={wavePlayerRef}
                  /> */}

                 
                  <div className="d-flex justify-content-center">
                    <Button
                      variant="secondary"
                      size="sm"
                      onClick={
                        callcondition[button_number] == "true"
                          ? () => {
                            blurvv(0);
                            paly_player(volume_btn - 14);
                          }
                          : () => {
                            dummy();
                          }
                      }
                    >
                      Close
                    </Button>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
          </Row>
        );
      })}
    </>
  );
};

export default SpeedDials;
